<template>
  <div class="d-flex flex-column flex-root">
    <!--begin::Head-->

    <!--end::Head-->

    <!--begin::Body-->
    <div
        class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid"
        id="kt_login"
    >

      <div
          class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <div class="text-center mb-5 mb-lg-5">
              <div class="block" style="background: #15232c; padding-top: 15px ">
                <a :href="siteUrl">
                  <img
                      src="/media/logos/score_logo.png"
                      alt="Score"
                      width="180px"
                  />
                </a>

              </div>
              <h3 class="font-size-h2 mb-5" style="background: #15232c;color: #fff;padding: 15px;">Setup a new password</h3>
              <p class="text-muted font-weight-semi-bold">

              </p>
            </div>

            <!--begin::Form-->
            <b-form class="kt-form" @submit.stop.prevent="onSubmit">
              <b-form-group id="example-input-group-5" label label-for="example-input-5">
                <b-form-input
                    class="form-control form-control-solid h-auto py-5 px-6"
                    type="password"
                    id="example-input-5"
                    name="example-input-5"
                    v-model="$v.form.password.$model"
                    :state="validateState('password')"
                    aria-describedby="input-5-live-feedback"
                    placeholder="Password"
                ></b-form-input>

                <b-form-invalid-feedback id="input-6-live-feedback">Password is required.</b-form-invalid-feedback>
              </b-form-group>

              <b-form-group id="example-input-group-6" label label-for="example-input-6">
                <b-form-input
                    class="form-control form-control-solid h-auto py-5 px-6"
                    type="password"
                    id="example-input-6"
                    name="example-input-6"
                    v-model="$v.form.confirmPassword.$model"
                    :state="validateState('confirmPassword')"
                    aria-describedby="input-6-live-feedback"
                    placeholder="Confirm password"
                ></b-form-input>

                <b-form-invalid-feedback id="input-6-live-feedback">Password should match .</b-form-invalid-feedback>
                <b-form-invalid-feedback id="input-6-live-feedback"  v-if="!$v.form.password.minLength">
                Password must have at least 6 characters.
                </b-form-invalid-feedback>

                <p v-if="form.password"><b>Strength:</b> <span v-html="checkStrength"></span></p>
              </b-form-group>

              <!--begin::Action-->
              <div
                  class="form-group d-flex flex-wrap justify-content-between align-items-center"
              >
                <a href="#" class="kt-link kt-login__link-forgot"></a>
                <button
                    type="submit"
                    id="reset_button"
                    ref="kt_login_signin_submit"
                    class="btn btn-elevate btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
                >Reset password</button>
              </div>
              <!--end::Action-->
            </b-form>
            <!--end::Form-->



          </div>
          <!--end::Signin-->
        </div>
      </div>

      <vue-recaptcha
        ref="invisibleRecaptcha"
        :sitekey="sitekey"
        :loadRecaptchaScript="true"
        size="invisible"
      ></vue-recaptcha>
    </div>
    <!--end::Body-->
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { API_URL} from "@/core/config";

import { REGISTER, LOGOUT, VERIFY_AUTH } from "@/core/services/store/auth.module";
import ApiService from "@/core/services/api.service";

import { validationMixin } from "vuelidate";
import { email, required, minLength, sameAs } from "vuelidate/lib/validators";
import VueRecaptcha from "vue-recaptcha";

export default {
  components: { VueRecaptcha },
  mixins: [validationMixin],
  name: "register",
  data() {
    return {
      sitekey: process.env.VUE_APP_CAPTCHA_SITE_KEY,
      siteUrl: API_URL,
      token: "",
      email: "",
      form: {
        password: "",
        confirmPassword: ""
      }
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8)
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password")
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        password: null
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$refs.invisibleRecaptcha.execute();
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const password = this.$v.form.password.$model;

      // set spinner to submit button
      const submitButton = document.getElementById("reset_button");
      submitButton.classList.add("kt-spinner");
      ApiService.post("admin/do-reset/password", {
        email: this.email,
        token: this.token,
        password: password
      }).then(response => {

        if(response.data.status=='OK')
        {
          this.$snotify.success(response.data.message, {
            position: "rightBottom",
            timeout: 5000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true
          });

          // dummy delay
          setTimeout(() => {
            submitButton.classList.remove("kt-spinner");
          }, 2000);
          setTimeout(() => {
            this.$router.push({ name: "login" });
          }, 5000);
        }

        if(response.data.status=='ERROR')
        {
          this.$snotify.error(response.data.message, {
            position: "rightBottom",
            timeout: 5000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true
          });

          // dummy delay
          setTimeout(() => {
            submitButton.classList.remove("kt-spinner");
          }, 2000);

        }

      });



    },
    AuthProvider(provider) {
      var self = this;
      this.$auth
        .authenticate(provider)
        .then(response => {
          self.SocialLogin(provider, response);
        })
        .catch(err => {
        });
    },

    SocialLogin(provider, response) {
      ApiService.get("/sanctum/csrf-cookie").then(() => {
        this.$http
          .post("/sociallogin/" + provider, response)
          .then(response => {
            this.$store
              .dispatch(SET_LOGIN, response.data)
              .then(() => this.$router.push({ name: "dashboard" }));
          })
          .catch(err => {
          });
      });
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/bg/bg-2.jpg";
    },

    validStrong() {
      return this.form.password.length <= 8 ;
    },
    allValid() {
      const containsSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(this.form.password);
      return (this.form.password.length > 7 && containsSpecial);
    },
    checkStrength() {
      if (this.form.password == '' && this.$v.form.password.$error)
        return '<span class="text-danger">Weak</span>';
      else if (this.$v.form.password.$error)
        return '<span class="text-danger">Weak</span>';
      else if (this.form.password != '' && !this.$v.form.password.$error && this.$v.form.password.minLength && this.validStrong)
        return '<span class="warning">Medium</span>';
      else if (this.form.password != '' && !this.$v.form.password.$error && this.$v.form.password.minLength && !this.validStrong && !this.allValid)
        return '<span class="text-success">Strong</span>';
      else if (this.form.password != '' && !this.$v.form.password.$error && this.$v.form.password.minLength && !this.validStrong && this.allValid)
        return '<span class="text-success">Strong</span>';
      return '<span class="text-red">Weak</span>';
    }
  },
  mounted: function() {},
  created: function() {
    this.token = this.$route.query.token ? this.$route.query.token : "";
    this.email = this.$route.query.email ? this.$route.query.email : "";

    ApiService.post("admin/check/reset/password", {
      token: this.token,
      email: this.email
    })
      .then(response=>{
        if (response.data.status=='EXPIRED')
        {
          this.$snotify.error(response.data.message, {
            position: "rightBottom",
            timeout: 5000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true
          });
        }

        /*setTimeout(() => {
          this.$router.push({ name: "forgot-password" });
        }, 5000);*/
      })
      .catch( response => {

      });
  }
};
</script>
